import React, { memo } from "react"
import TypingAnimation from "./TypingAnimation"
import { FlexContainer, FlexItem } from "../../../styled/layout.styled"
import { Caption, H1, Source } from "../../../styled/typography.styled"
import ProfilePicture from "./ProfilePicture"
import Button from "../../reuseAble/Button"
import { Link } from "gatsby"
import Pdf from "../../../assets/pdf/Sarkis_Kozanoghli_-_Javascript_Developer.pdf"
import styled from "@emotion/styled"

const StyledFlexContainer = styled(FlexContainer)`
  height: 496px;
  align-items: center;
  @media only screen and (max-width: 970px) {
    height: auto;
  }
`

const ImageWrapper = styled(FlexContainer)`
  width: 445px;
  @media only screen and (max-width: 1264px) {
    width: 330px;
  }

  @media only screen and (max-width: 970px) {
    width: 290px;
  }
`

const SectionOneMain = () => {
  return (
    <StyledFlexContainer
      padding="50px 0 50px 0"
      flexWrap="wrap"
      width="100%"
      flexDirection="row-reverse"
      justifyContent="space-evenly"
      alignItems="center"
      mobileJustifyContent="center"
      mobileFlexDirection="column"
    >
      <ImageWrapper
        mobilePadding="30px 0 30px 0"
        mobileWidth="270px"
        width="330px"
        justifyContent="space-evenly"
      >
        <ProfilePicture />
      </ImageWrapper>
      <FlexContainer flexDirection="column" mobileAlignItems="center">
        <TypingAnimation />
        <H1 margin="0 0 20px 0" mobileFontSize="30px">
          Sarkis Kozanoghli
        </H1>
        <Caption
          minWidth="270px"
          mobileWidth="350px"
          textAlign="center"
          width="335px"
          margin="0 0 27px 0"
        >
          A fast learner enthusiastic JavaScript developer mainly Front-end who
          enjoys coding and learning new technologies besides working hard and
          producing good quality code.
        </Caption>
        <FlexContainer
          mobileWidth="100%"
          mobileJustifyContent="center"
          mobileFlexDirection="column"
        >
          <FlexItem>
            <Source
              opacity="1"
              href={Pdf}
              target="_blank"
              rel="noreferrer noopener"
              download
            >
              <Button minWidth="100%" mobileWidth="100%" width="160px">
                Download CV
              </Button>
            </Source>
          </FlexItem>
          <FlexItem mobileMargin="15px 0 0 0" margin="0 0 0 10px">
            <Link to={"/contact"}>
              <Button
                minWidth="100%"
                mobileWidth="100%"
                width="130px"
                borderColor={1}
              >
                Contact
              </Button>
            </Link>
          </FlexItem>
        </FlexContainer>
      </FlexContainer>
    </StyledFlexContainer>
  )
}

export default memo(SectionOneMain)
