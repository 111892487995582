import React, { memo } from "react"
import Typical from "react-typical"
import { P } from "../../../styled/typography.styled"
import styled from "@emotion/styled"

const StyledP = styled(P)`
  font-size: 16px;
  font-weight: 300;
  color: #aaa;
`

const TypingAnimation = () => {
  return (
    <>
      <StyledP>
        <Typical
          loop={Infinity}
          wrapper="span"
          steps={["Hello There!", 1000, "I'm a web developer", 1000]}
        />
      </StyledP>
    </>
  )
}

export default memo(TypingAnimation)
