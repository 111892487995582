import React from "react"
import SEO from "../components/seo"
import LayoutRoot from "../components/LayoutRoot"
import Layout from "../components/layout"
import SectionOneMain from "../components/aboutMe/sectionOne/SectionOneMain"

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <LayoutRoot>
        <Layout>
          <SectionOneMain />
        </Layout>
      </LayoutRoot>
    </>
  )
}

export default IndexPage
