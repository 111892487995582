import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"

const PictureContainer = styled.div`
  background-color: #444;
  border: 18px solid #444;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.8);
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: calc(100% - 36px);
  border-radius: 1000px;
  overflow: hidden;
`

const StyledPicture = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  display: block;
  position: absolute;
  top: -45px;
  bottom: -18px;
  width: auto;
  left: -18px;
  right: -18px;
  @media only screen and (max-width: 970px) {
    top: -28px;
  }
`

const ProfilePicture = () => {
  const data = useStaticQuery(graphql`
     query {
    contentfulAsset(id: {eq: "f3c985d5-abdb-50f2-b412-834b368ffb4d"}) {
    id
    title
    file {
      url
    }
  }
}`)

  const profileImage = data.contentfulAsset.file.url

  return (
    <PictureContainer>
      <StyledPicture image={profileImage} />
    </PictureContainer>
  )
}

export default ProfilePicture
